$(document).ready(function () {
  new WOW().init();
});
// table
$(".table-responsive-stack").each(function (i) {
  var id = $(this).attr("id");
  //alert(id);
  $(this)
    .find("th")
    .each(function (i) {
      $("#" + id + " td:nth-child(" + (i + 1) + ")").prepend(
        '<span class="table-responsive-stack-thead">' +
          $(this).text() +
          ":</span> "
      );
      $(".table-responsive-stack-thead").hide();
    });
});

$(".table-responsive-stack").each(function () {
  var thCount = $(this).find("th").length;
  var rowGrow = 100 / thCount + "%";
  //console.log(rowGrow);
  $(this).find("th, td").css("flex-basis", rowGrow);
});

function flexTable() {
  if ($(window).width() < 768) {
    $(".table-responsive-stack").each(function (i) {
      $(this).find(".table-responsive-stack-thead").show();
      $(this).find("thead").hide();
    });

    // window is less than 768px
  } else {
    $(".table-responsive-stack").each(function (i) {
      $(this).find(".table-responsive-stack-thead").hide();
      $(this).find("thead").show();
    });
  }
  // flextable
}

flexTable();

window.onresize = function (event) {
  flexTable();
};

// document ready

if ($(window).width() <= 991) {
  $(".wow").removeClass("wow");
}

$(window).on("scroll", function () {
  var scroll = $(window).scrollTop();
  if (scroll < 100) {
    $(".header").removeClass("sticky-bar");
  } else {
    $(".header").addClass("sticky-bar");
  }
});

$(function () {
  $("#ChangeToggle").click(function () {
    $("#navbar-hamburger").toggleClass("show");
    $("#navbar-close").toggleClass("show");
  });
});

// result
$(function () {
  $("#finish").click(function () {
    let cancelNext = $("#cancelNext")[0].dataset.cancelNext;
    if (cancelNext == "true") {
      return;
    }
    $("#result").attr("style", "display:block");
    $("#form").attr("style", "display:none");
  });
});
$(function () {
  $("#back").click(function () {
    $("#result").attr("style", "display:none");
    $("#form").attr("style", "display:block");
    $(".step").removeClass("done");
    $("#step2").removeClass("active");
    $("#step3").removeClass("active");
    $("#step4").removeClass("active");
    $("#step5").removeClass("active");
    $(".fieldset1").attr("style", "display:block; opacity:1");
  });
});
